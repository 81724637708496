<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            <!--Tabs-->
            <v-tabs vertical height="75vh" @change="tabChanged">
              <v-tab
                  v-for="(option, index) of options"
                  :key="index"
              >{{option.title}}</v-tab>
              <!--Tab Items-->
              <v-tab-item
                  v-for="(option, index) of options"
                  :key="index"
                  class="px-3"
              >
                <div v-if="option.vueComponent">
                  <component
                      v-bind:is="option.vueComponent"
                      :key="settingsKey"
                      @save-settings="saveSettings"
                      :domain="domain"
                  />
                </div>
                <v-card color="info" dark v-else width="500">
                  <v-card-title><v-icon left>mdi-alert-circle</v-icon>Info</v-card-title>
                  <v-card-text>Not yet implemented</v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "settingsTabItem",
  props: {
    domain:{
      type: String,
      required: true
    }
  },
  data() {
    return{
      currentTabitem: null,
      settingsKey: 0,
      tabs: 0,
      options:[
        {title: 'Credentials', vueComponent: () => import('./credentials')},
        {title: 'SIPIS', vueComponent: () => import('./sipis')},
        {title: 'Proxies', vueComponent: () => import('./proxies')},
        {title: 'Voicemail', vueComponent: () => import('./voicemail')},
        {title: 'Audio Codecs', vueComponent: () => import('./audioCodecs')},
        {title: 'Opus Codecs'},
        {title: 'Video Codecs', vueComponent: () => import('./videoCodecs')},
        {title: 'DTMF', vueComponent: () => import('./dtmf')},
        {title: 'NAT Traversal', vueComponent: () => import('./natTraversal')},
        {title: 'Custom SIP Headers'},
        {title: 'DND'},
        {title: 'Number Rewriting'},
        {title: 'Incoming Calls', vueComponent: () => import('./incomingCalls')},
        {title: 'Web Callback'},
        {title: 'Call Through'},
        {title: 'Miscellaneous'},
        {title: 'Call Tones'},
        {title: 'Auto Answer'},
        {title: 'Busy Lamp Field', vueComponent: () => import('./blf')},
        {title: 'WebRTC Options'},
        {title: 'Video Conferencing'}
      ]
    }
  },
  methods:{
    saveSettings(config) {
      this.$emit('save-settings', config)
    },
    tabChanged() {
      this.settingsKey = this.settingsKey + 1
    }
  }
}
</script>

<style scoped>

</style>