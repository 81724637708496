<template>
  <div>
    <v-row>
      <v-col>
        <v-toolbar flat color="transparent" class="mb-3">
          <span class="body-1">Provisioning logs</span>
          <v-spacer/>
          <v-text-field
              filled
              rounded
              dense
              placeholder="Search..."
              append-icon="mdi-magnify"
              v-model="search"
          />
        </v-toolbar>
        <v-data-table
            :headers="headers"
            :items="logs"
            :search="search"
        >

        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "logsTabItem",
  data() {
    return{
      headers: [
        {text: 'User', value: 'user'},
        {text: 'Domain', value: 'domain'},
        {text: 'Date', value: 'createdAt'}
      ],
      logs: [],
      search: ''
    }
  }
}
</script>

<style scoped>

</style>