<template>
  <div>
    <!--Toolbar-->
    <v-toolbar flat>
      <v-toolbar-title class="body-1 text-capitalize">
        {{ domain ? `${domainId} provision`:'Acrobits Global Default' }} Settings
      </v-toolbar-title>
      <v-divider vertical inset class="mx-3"/>
      <!-- Download text config xml -->
      <v-tooltip fixed top>
        <template v-slot:activator="{on}">
          <v-btn icon color="primary" v-on="on" @click="downloadTestConfig"><v-icon>mdi-file-download</v-icon></v-btn>
        </template>
        Download a current test config
      </v-tooltip>
    </v-toolbar>
    <v-row>
      <v-col>
        <v-tabs icons-and-text>

          <!--Settings-->
          <v-tab ripple>
            Settings
            <v-icon>mdi-cog</v-icon>
          </v-tab>
          <v-tab-item class="px-3">
            <settingsTabItem
                :domain="domain ? domainId:'global'"
                @save-settings="saveSettings"
            ></settingsTabItem>
          </v-tab-item>

          <!--Logs-->
          <v-tab>
            Logs
            <v-icon>mdi-phone-log</v-icon>
          </v-tab>
          <v-tab-item class="px-3">
            <logsTabItem></logsTabItem>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <router-view/>
  </div>
</template>

<script>
import settingsTabItem from './components/settingsTabItem'
import logsTabItem from "@/views/pages/acrobits/components/logsTabItem";
export default {
  name: "acrobits",
  props: {
    domain: {
      type: Boolean,
      required: false,
      default: false,
    },
    domainId:{
      type: String,
      required: false,
      default: null
    }
  },
  components:{
    settingsTabItem,
    logsTabItem
  },
  data() {
    return{

    }
  },
  methods: {
    saveSettings(config) {
      this.$axios.post('acrobits/save-settings', {config: config, isDomain: this.domain, domain: this.domainId}).then(()=>{
        this.$toasted.show('Settings saved!', {type: 'success'})
      }).catch(error=>{this.$toasted.show(error.response.data.message, {type: 'error'})})
    },
    downloadTestConfig() {
      this.$axios.get('acrobits/download-test-config').then(res=>{
        let fileURL = window.URL.createObjectURL(new Blob([res.data]))
        let fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'textconfig.xml')
        document.body.append(fileLink)
        fileLink.click()
      })
    }
  }
}
</script>

<style scoped>

</style>